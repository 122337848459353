
















































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetResponsaveis,
  dispatchUpdateResponsavel,
} from '@/store/responsavel/actions';
import { consultarResponsavel } from '@/store/responsavel/getters';
import { IResponsavelUpdate } from '@/interfaces/responsavel';

@Component
export default class AlterarResponsavel extends Vue {
  public valid = true;
  public nome: string = '';
  public sigla: string = '';
  public porLocalidade: boolean = false;
  public porPontoAcesso: boolean = false;

  public async mounted() {
    await dispatchGetResponsaveis(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.responsavel) {
      this.nome = this.responsavel.nome;
      this.sigla = this.responsavel.sigla;
      this.porLocalidade = this.responsavel.por_localidade;
      this.porPontoAcesso = this.responsavel.por_ponto_acesso;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const responsavelAtualizado: IResponsavelUpdate = {
        nome: this.nome,
        sigla: this.sigla,
        por_localidade: this.porLocalidade,
        por_ponto_acesso: this.porPontoAcesso,
      };

      const atualizou = await dispatchUpdateResponsavel(this.$store, {
        id: this.responsavel!.id,
        responsavel: responsavelAtualizado,
      });
      if (atualizou) {
        this.$router.push('/main/responsavel/listar');
      }
    }
  }

  get responsavel() {
    return consultarResponsavel(this.$store)(+this.$router.currentRoute.params.id);
  }
}
